import { ApiFactory, createApiFactory, createApiRef } from '@backstage/core-plugin-api';
import OktaAuth, { OktaAuthOptions, TokenParams } from '@okta/okta-auth-js';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { retryable } from 'backstage-plugin-czi-extensions-common';
import { LocalStorageCache } from '../helpers/localStorageCache';

export const oktaTokenApiRef = createApiRef<OktaTokenApi>({ id: 'okta.token-api' });

export interface OktaTokenApi {
  getToken(clientId: string, issuerUrl?: string): Promise<string>;
}

export class OktaToken implements OktaTokenApi {
  static createDefaultApiFactory(): ApiFactory<OktaTokenApi, OktaToken, {}> {
    return createApiFactory({
      api: oktaTokenApiRef,
      deps: { },
      factory: () => new OktaToken(),
    });
  }

  async getToken(clientId: string, issuerUrl: string = 'https://czi-prod.okta.com'): Promise<string> {
    const cachedTokenKey = `cachedToken-${clientId}-${issuerUrl}`;
    const token = await LocalStorageCache.get<string>(cachedTokenKey, {
      ttlSeconds: 600, // 10 minutes
      fetchFn: () => this.generateToken(clientId, issuerUrl),
      validateFn: (t: string) => !this.isExpired(t),
    });
    return token;
  }

  // returns true if the token expires within the threshold
  isExpired(token: string, thresholdSeconds: number = 10 * 60, decoder = jwtDecode<JwtPayload>): boolean {
    const decoded = decoder(token);
    if (!decoded.exp) {
      throw new Error('Okta token did not have expected exp value');
    }

    const secondsSinceEpoch = Math.round(new Date().getTime() / 1000);
    return (secondsSinceEpoch + thresholdSeconds) > decoded.exp;
  }

  async generateToken(clientId: string, issuerUrl: string): Promise<string | undefined> {
    return retryable(async () => {
      const oktaConfig: OktaAuthOptions = {
        issuer: issuerUrl,
      };
      const clientAuth: OktaAuth = new OktaAuth(oktaConfig);
      const tokenConfig: TokenParams = {
        pkce: true,
        clientId,
      };
      const { tokens: { idToken } } = await clientAuth.token.getWithPopup(tokenConfig);
      return idToken?.idToken;
    });
  }
}
