import React from 'react';
import {
  Link, Progress, Table, TableColumn,
} from '@backstage/core-components';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';
import { catalogApiRef, entityRouteRef } from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/lib/useAsync';
import { Alert } from '@material-ui/lab';
import { parseEntityRef, stringifyEntityRef } from '@backstage/catalog-model';
import {
  Grid, GridProps,
} from '@material-ui/core';

export function HappyAppsTile(props: GridProps): JSX.Element | null {
  const catalogApi = useApi(catalogApiRef);
  const entityRoute = useRouteRef(entityRouteRef);

  const { value: happyApps, loading, error } = useAsync(async (): Promise<any> => (await catalogApi.getEntities({
    filter: { kind: 'Component', 'metadata.annotations.happy_app': 'true' },
  })).items, []);

  if (loading) {
    return <Progress />;
  } if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (!happyApps || happyApps.length === 0) {
    return null;
  }

  const columns: TableColumn[] = [
    {
      title: 'App Name',
      field: 'metadata.name',
      render: (entity: any) => (
        <Link to={`${entityRoute(parseEntityRef(stringifyEntityRef(entity)))}/happy`}>
          {entity.metadata.name}
        </Link>
      ),
    },
    {
      title: 'Owner',
      field: 'spec.owner',
      render: (entity: any) => (
        <Link to={entityRoute(parseEntityRef(entity.spec.owner, { defaultKind: 'group', defaultNamespace: 'default' }))}>
          {entity.spec.owner}
        </Link>
      ),
    },
  ];

  return (
    <Grid item {...props}>
      <Table
        title="Your Happy Apps"
        data={happyApps}
        columns={columns}
        style={{ padding: '10px', width: '100%' }}
        options={{
          pageSize: 10,
        }}
      />
    </Grid>
  );
}
