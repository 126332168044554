import { snooze } from './snooze';

type RetryAbleAsyncFunction<T> = () => Promise<T>;

export type RetryableOptions = {
  // Number of retries
  retries?: number

  // Time to wait between retries
  snoozeMs?: number

  // Function to call before each retry
  refreshFn?: () => Promise<void>
};

export async function retryable<T>(f: RetryAbleAsyncFunction<T>, opts: RetryableOptions = {}): Promise<T> {
  const retries = opts.retries ?? 5;
  const snoozeMs = opts.snoozeMs ?? 250;
  const refreshFn = opts.refreshFn ?? (async (): Promise<void> => {});

  try {
    const result: T = await f();
    return result;
  } catch (err) {
    if (retries > 0) {
      await snooze(snoozeMs);
      await refreshFn();
      return retryable(f, { retries: retries - 1, snoozeMs: snoozeMs * 2, refreshFn });
    }
    throw err;
  }
}
