import { OAuthApi, createApiRef } from '@backstage/core-plugin-api';
import { Octokit } from '@octokit/rest';

export const happyConfigApiRef = createApiRef<HappyConfigAPI>({ id: 'happy.github-happy-config' });

export interface HappyConfigAPI {
  getHappyConfig(repoSlug: string, directory: string): Promise<any>;
}

type GetContentData = {
  content: any
};

export type HappyConfigResponse = {
  app: string
  environments: any
  features?: { [key: string]: any }
};

function getOctokitClient(auth: string): any {
  return new Octokit({ auth, userAgent: 'eng-portal' });
}

export class HappyConfig implements HappyConfigAPI {
  githubAuthApi: OAuthApi;

  constructor(githubAuthApi: OAuthApi) {
    this.githubAuthApi = githubAuthApi;
  }

  async getHappyConfig(repoSlug: string, directory: string, clientFactory = getOctokitClient): Promise<HappyConfigResponse> {
    const slugParts = repoSlug.split('/');
    const owner = slugParts[0];
    const repo = slugParts[1];
    const path = [directory, 'config.json'].join('/');

    const auth = await this.githubAuthApi.getAccessToken(['repo']);
    const octokit = clientFactory(auth);
    const response = await octokit.rest.repos.getContent({ owner, repo, path });

    const content = (response.data as GetContentData).content;
    const buffer = Buffer.from(content, 'base64');
    return JSON.parse(buffer.toString());
  }
}
