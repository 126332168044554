import {
  Button, Grid, IconButton, TextField, Tooltip,
} from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import React from 'react';

export type MultiTextFieldProps = {
  values: string[];
  disabled?: boolean;
  onChange: (values: string[]) => void;
};

MultiTextField.defaultProps = {
  disabled: false,
};

export function MultiTextField(props: MultiTextFieldProps): JSX.Element {
  const { values, disabled, onChange } = props;

  return (
    <Grid container direction="column" spacing={1}>
      {values.map((value, index) => (
        <Grid item container direction="row">
          <Grid item>
            <TextField
              value={value}
              disabled={disabled}
              onChange={(e): void => {
                const newValues = [...values];
                newValues[index] = e.target.value;
                onChange(newValues);
              }}
              InputProps={disabled ? undefined : {
                endAdornment: (
                  <Tooltip title="Remove">
                    <IconButton
                      size="small"
                      color="secondary"
                      disabled={disabled}
                      onClick={(): void => {
                        const newValues = [...values];
                        newValues.splice(index, 1);
                        onChange(newValues);
                      }}
                    >
                      <Remove />
                    </IconButton>
                  </Tooltip>
                ),
              }}
            />
          </Grid>
        </Grid>
      ))}
      { disabled ? null
        : (
          <Grid item container direction="row" justifyContent="flex-end">
            <Button
              color="primary"
              disabled={disabled}
              onClick={(): void => {
                onChange([...values, '']);
              }}
              startIcon={<Add />}
            >
              Add
            </Button>
          </Grid>
        )}
    </Grid>
  );
}
