import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { HappyDirectoryPathSpecifier } from './HappyDirectoryPathSpecifier';

export const HappyDirectoryPathSpecifierExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'HappyDirectoryPathSpecifier',
    component: HappyDirectoryPathSpecifier,
  }),
);
