import React, { useEffect, useState } from 'react';
import {
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField,
} from '@material-ui/core';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

export function formatInput(input: string): string {
  const parts = input.split('/').filter((part) => part.length > 0 && part !== '.');

  if (parts.length === 0 || parts[parts.length - 1] !== '.happy') {
    parts.push('.happy');
  }

  return parts.join('/');
}

export function HappyDirectoryPathSpecifier(props: FieldExtensionComponentProps<string>): JSX.Element {
  const { onChange } = props;

  const defaultValue = '.happy';
  const [useCustomPath, setUseCustomPath] = useState(false);
  const [customPath, setCustomPath] = useState(defaultValue);

  const radioSelectionChanged = (_: any, value: string): void => {
    setUseCustomPath(value === 'false');
  };
  const handleCustomPathChange = (event: any): void => {
    setCustomPath(event.target.value);
  };
  useEffect(() => {
    onChange(useCustomPath ? formatInput(customPath) : defaultValue);
  }, [useCustomPath, customPath, onChange]);

  return (
    <FormControl>
      <FormLabel id="happy-dir-radio-buttons-group">Is .happy directory at root of repository?</FormLabel>
      <RadioGroup
        aria-labelledby="happy-dir-radio-buttons-group"
        defaultValue="true"
        name="radio-buttons-group"
        onChange={radioSelectionChanged}
      >
        <FormControlLabel value="true" control={<Radio />} label="Yes" />
        <FormControlLabel value="false" control={<Radio />} label="No" />
      </RadioGroup>
      <TextField
        style={{ width: '100%', display: useCustomPath ? 'block' : 'none' }}
        label="Path to directory in your repo that contains the .happy directory"
        variant="standard"
        onChange={handleCustomPathChange}
        value={customPath}
        required
      />
    </FormControl>
  );
}
