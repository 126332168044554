import {
  configApiRef, createApiFactory, createPlugin, createRoutableExtension,
} from '@backstage/core-plugin-api';
import { oktaTokenApiRef } from 'backstage-plugin-czi-extensions-react';
import { rootRouteRef } from './routes';
import { ScruffyAPIClient, scruffyApiRef } from './apis/scruffyApi';

export const scruffyPlugin = createPlugin({
  id: 'scruffy',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: scruffyApiRef,
      deps: { oktaTokenApi: oktaTokenApiRef, configApi: configApiRef },
      factory: ({ oktaTokenApi, configApi }) => new ScruffyAPIClient(oktaTokenApi, configApi),
    }),
  ],
});

export const ScruffyPage = scruffyPlugin.provide(
  createRoutableExtension({
    name: 'ScruffyPage',
    component: () => import('./components/Router').then((m) => m.Router),
    mountPoint: rootRouteRef,
  }),
);
