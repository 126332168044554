import '@backstage/cli/asset-types';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';

const { host } = new URL(window.location.href);
if (!host.includes('localhost')) {
  // something like: [ 'eng-portal', 'staging', 'si', 'czi', 'technology' ]
  const hostSplit = host.split('.');

  let environment = 'unknown';
  if (hostSplit.length >= 5) {
    environment = hostSplit[hostSplit.length - 4];
  }
  Sentry.init({
    dsn: 'https://0faf4a4cbe3741ea827ce3c4aa201b00@o703207.ingest.sentry.io/4504532252884992',
    environment,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
