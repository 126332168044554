import React from 'react';
import { Content, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import { HomePageCompanyLogo } from '@backstage/plugin-home';
import { HomePageTiles } from './components/HomePageTiles';

/*
 * Ref:
 *  - https://backstage.io/docs/getting-started/homepage
 *  - https://backstage.io/storybook/?path=/story/plugins-home-templates--default-template
 */

const useStyles = makeStyles((theme) => ({
  homePageHeader: {
    margin: theme.spacing(8, 0),
  },
  searchBar: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export function HomePage(): JSX.Element {
  const classes = useStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container direction="column">
            <Grid container item direction="row" justifyContent="center" spacing={2}>
              <Grid item>
                <HomePageCompanyLogo className={classes.homePageHeader} />
              </Grid>
            </Grid>
            <Grid container item direction="column" alignItems="center" spacing={6}>
              <Grid item style={{ width: '70%' }}>
                <HomePageSearchBar
                  classes={{ root: classes.searchBar }}
                  placeholder="Search"
                />
              </Grid>
              <HomePageTiles />
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
}
