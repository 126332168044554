import { ConfigApi, IdentityApi, createApiRef } from '@backstage/core-plugin-api';
import { AWSCredentialsAPI } from 'backstage-plugin-czi-extensions-react';
import {
  InvocationRequest, InvokeCommand, InvokeCommandOutput, LambdaClient,
} from '@aws-sdk/client-lambda';

export const awsKeyManagementApiRef = createApiRef<AWSKeyManagementAPI>({ id: 'key-management.api' });

export interface AWSKeyManagementAPI {
  createKey(req: CreateKeyUserInput): Promise<CreateKeyResponse>
  deleteKey(aws_account_id: string, iam_user_name: string): Promise<DeleteKeyResponse>
  listKeys(): Promise<ListKeyResponse>
}

export type RequestAction = 'create' | 'delete' | 'list';

export type IAMStatement = {
  Sid?: string
  Effect: 'Allow' | 'Deny'
  Action: string[]
  Resource: string | string[]
  Condition?: any // TODO: define the condition type
  Principals?: string[]
};

export type CreateKeyUserInput = {
  end_users_emails: string[] // End User Emails
  czi_users_emails: string[] // Internal CZI contact emails
  aws_account_id: string
  iam_user_name: string
  expiration: string, // Expiration in ISO 8601
  purpose: string
  iam_statements: IAMStatement[]
};

export type CreateKeyRequest = CreateKeyUserInput & {
  action: 'create'
  central_creator_email: string // Hidden field that is pulled from the identity of the signed-in user
};

type AccessKey = {
  UserName: string
  AccessKeyId: string
  Status: string
  SecretAccessKey: string
};

export type CreateKeyResponse = {
  success: true
  message: string
  error?: never
  AccessKey: AccessKey
} | {
  success: false
  message?: never
  error: string
  AccessKey?: never
};

export type ListKeyRequest = {
  action: 'list'
};

export type KeyResponseItem = {
  purpose: string
  central_creator_email: string
  iam_user_name: string
  czi_users_emails: string[]
  current_status: string
  creation: string
  iam_statements: IAMStatement[]
  expiration: string
  id: string
  account_id: string
  end_users_emails: string[]
};

export type ListKeyResponse = {
  keys: KeyResponseItem[]
  success: boolean
  error?: string
  approved_aws_accounts: Record<string, string>
};

export type DeleteKeyRequest = {
  action: 'delete'
  aws_account_id: string
  iam_user_name: string
};
export type DeleteKeyResponse = {
  success: boolean
  message: string
  error?: string
};

// Use this to make invoke the AWS Key Management Lambda
export class AWSKeyManagementClient implements AWSKeyManagementAPI {
  constructor(
    private readonly awsCredentialsApi: AWSCredentialsAPI,
    private readonly identityApi: IdentityApi,
    private readonly configApi: ConfigApi,
  ) {}

  async createKey(input: CreateKeyUserInput): Promise<CreateKeyResponse> {
    const profileInfo = await this.identityApi.getProfileInfo();
    if (!profileInfo.email) {
      throw new Error('Failed to get profile info');
    }
    const req: CreateKeyRequest = {
      action: 'create',
      central_creator_email: profileInfo.email,
      aws_account_id: input.aws_account_id,
      iam_user_name: input.iam_user_name,
      czi_users_emails: input.czi_users_emails,
      end_users_emails: input.end_users_emails,
      expiration: input.expiration,
      iam_statements: input.iam_statements,
      purpose: input.purpose,
    };

    try {
      const client = await this.getLambdaClient();
      const command = this.getInvokeCommand(JSON.stringify(req));
      const response: InvokeCommandOutput = await client.send(command);
      const payload = response.Payload?.transformToString();
      if (!payload) {
        throw new Error('Failed to get response from AWS Lambda');
      }
      return JSON.parse(payload);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to create keys:', err);
      throw err;
    }
  }

  async deleteKey(aws_account_id: string, iam_user_name: string): Promise<DeleteKeyResponse> {
    const req: DeleteKeyRequest = {
      action: 'delete',
      aws_account_id,
      iam_user_name,
    };

    try {
      const client = await this.getLambdaClient();
      const command = this.getInvokeCommand(JSON.stringify(req));
      const response: InvokeCommandOutput = await client.send(command);
      const payload = response.Payload?.transformToString();
      if (!payload) {
        throw new Error('Failed to get response from AWS Lambda');
      }
      return JSON.parse(payload);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to delete keys:', err);
      throw err;
    }
  }

  async listKeys(): Promise<ListKeyResponse> {
    const req: ListKeyRequest = {
      action: 'list',
    };

    try {
      const client = await this.getLambdaClient();
      const command = this.getInvokeCommand(JSON.stringify(req));
      const response: InvokeCommandOutput = await client.send(command);
      const payload = response.Payload?.transformToString();
      if (!payload) {
        throw new Error('Failed to get response from AWS Lambda');
      }
      return JSON.parse(payload);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to list keys:', err);
      throw err;
    }
  }

  getInvokeCommand(payload: string): InvokeCommand {
    const input: InvocationRequest = {
      FunctionName: this.configApi.getString('plugins.keyManagement.lambdaFunctionName'),
      InvocationType: 'RequestResponse',
      ClientContext: Buffer.from(JSON.stringify({ 'User-Agent': 'eng-portal' })).toString('base64'),
      Payload: Buffer.from(payload),
    };
    return new InvokeCommand(input);
  }

  async getLambdaClient(): Promise<LambdaClient> {
    const credentials = await this.awsCredentialsApi.getSTSCredential('czi-sec', ['okta-czi-admin']);
    return new LambdaClient({
      region: 'us-west-2',
      credentials: {
        accessKeyId: credentials?.AccessKeyId!,
        secretAccessKey: credentials?.SecretAccessKey!,
        sessionToken: credentials?.SessionToken,
      },
    });
  }
}
