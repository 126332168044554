import {
  Content, Header, Page, SupportButton,
} from '@backstage/core-components';
import { TechDocsReaderPageContent } from '@backstage/plugin-techdocs';
import { TechDocsReaderPageProvider } from '@backstage/plugin-techdocs-react';
import { Grid } from '@material-ui/core';
import React from 'react';

export function HelpCenterPage(): JSX.Element {
  return (
    <Page themeId="home">
      <Header title="Help Center">
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <SupportButton />
          </Grid>
        </Grid>
      </Header>
      <Content>
        <TechDocsReaderPageProvider entityRef={{ kind: 'Component', namespace: 'default', name: 'eng-portal-help-center' }}>
          <TechDocsReaderPageContent withSearch={false} />
        </TechDocsReaderPageProvider>
      </Content>
    </Page>
  );
}
