import { ConfigApi, createApiRef } from '@backstage/core-plugin-api';
import { OktaTokenApi } from 'backstage-plugin-czi-extensions-react';

export const oktaHappyApiTokenApiRef = createApiRef<OktaHappyApiTokenAPI>({ id: 'okta.happy-api-token' });

export interface OktaHappyApiTokenAPI {
  getTokenForUrl(hapiUrl: string): Promise<string | undefined>;
}

export class OktaHappyApiToken implements OktaHappyApiTokenAPI {
  oktaTokenApi: OktaTokenApi;
  configApi: ConfigApi;

  constructor(oktaTokenApi: OktaTokenApi, configApi: ConfigApi) {
    this.oktaTokenApi = oktaTokenApi;
    this.configApi = configApi;
  }

  async getTokenForUrl(hapiUrl: string): Promise<string | undefined> {
    return this.oktaTokenApi.getToken(this.getClientIdForUrl(hapiUrl));
  }

  getClientIdForUrl(hapiUrl: string): string {
    const url = new URL(hapiUrl);

    // default to using the prod client id
    let clientId = this.configApi.getString('plugins.happy.happyApi.oktaClientIdProd');

    if (url.host.endsWith('.hapi.rdev.si.czi.technology')) {
      clientId = this.configApi.getString('plugins.happy.happyApi.oktaClientIdRdev');
    } else if (url.host.endsWith('.hapi.staging.si.czi.technology')) {
      clientId = this.configApi.getString('plugins.happy.happyApi.oktaClientIdStaging');
    }

    return clientId;
  }
}
