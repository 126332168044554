import { ConfigApi, IdentityApi, createApiRef } from '@backstage/core-plugin-api';
import axios from 'axios';

export const happyBackendApiRef = createApiRef<HappyBackendAPI>({ id: 'happy.backend' });

export interface HappyBackendAPI {
  getPublicDashboardUrl(dashboardId: string): Promise<string>
}

function getDatadogDashboardPublicUrl(config: ConfigApi): string {
  return `${config.getOptionalString('backend.baseUrl') || ''}/api/happy/datadogDashboardPublicUrl`;
}

// Use this to make requests to the Happy Backend within eng-portal
export class HappyBackend implements HappyBackendAPI {
  constructor(private identityApi: IdentityApi, private configApi: ConfigApi) {}

  async getPublicDashboardUrl(dashboardId: string): Promise<string> {
    const response = await axios.get<{ publicUrl: string }>(getDatadogDashboardPublicUrl(this.configApi), {
      params: {
        dashboardId,
      },
      headers: {
        Authorization: await this.getCurrentUserToken(),
      },
    });
    return response.data.publicUrl;
  }

  private async getCurrentUserToken(): Promise<string> {
    const currentUserToken = (await this.identityApi.getCredentials()).token;
    if (!currentUserToken) {
      throw new Error('User is not authenticated');
    }
    return `Bearer ${currentUserToken}`;
  }
}
