import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { Autocomplete, TextField } from '@mui/material';

export type SelectAutocompleteOption = {
  value: string
  label: string
};

export type SelectAutocompleteProps = {
  label?: string
  items: SelectAutocompleteOption[]
  selectedItems: SelectAutocompleteOption[]
  setSelectedItems: React.Dispatch<SelectAutocompleteOption[]>
  allowSelectAll?: boolean
  sortItems?: boolean
  sortItemsFn?: (a: SelectAutocompleteOption, b: SelectAutocompleteOption) => number
  multiple?: boolean
};

SelectAutocomplete.defaultProps = {
  label: undefined,
  allowSelectAll: true,
  sortItems: false,
  sortItemsFn: (a: SelectAutocompleteOption, b: SelectAutocompleteOption): number => a.label.localeCompare(b.label),
  multiple: false,
};

const useSelectAutocompleteStyles = makeStyles((theme: Theme) => createStyles({
  input: {
    color: `${theme.palette.text.primary} !important`,
    backgroundColor: `${theme.palette.background.paper} !important`,
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: `${theme.palette.text.primary} !important`,
  },
  noOptions: {
    color: `${theme.palette.text.secondary} !important`,
  },
  clearIndicator: {
    color: `${theme.palette.text.primary} !important`,
  },
  paper: {
    backgroundColor: `${theme.palette.background.paper} !important`,
    color: `${theme.palette.text.primary} !important`,
    boxShadow: `0px 4px 5px 0px ${theme.palette.background.default}`,
  },
  chipPaper: {
    backgroundColor: `${theme.palette.background.default} !important`,
    borderRadius: '25px',
  },
  chipDeleteIcon: {
    color: `${theme.palette.text.primary} !important`,

  },
  popupIndicator: {
    color: `${theme.palette.type === 'dark' ? theme.palette.text.primary : theme.palette.text.secondary} !important`,
  },
  inputLabelColor: {
    color: `${theme.palette.text.primary} !important`,
  },
}));

export function SelectAutocomplete(props: SelectAutocompleteProps): JSX.Element {
  const {
    label, items: initItems, selectedItems, setSelectedItems, allowSelectAll, sortItems, sortItemsFn, multiple,
  } = props;

  const selectAllItem = { label: 'All', value: '*' };
  const sortedInitItems = sortItems ? initItems.sort(sortItemsFn) : initItems;
  const items: SelectAutocompleteOption[] = allowSelectAll && multiple && initItems.length > 0
    ? [selectAllItem, ...sortedInitItems]
    : sortedInitItems;

  const classes = useSelectAutocompleteStyles();

  const handleSelection = (selection: SelectAutocompleteOption | SelectAutocompleteOption[] | null): void => {
    if (!selection) {
      setSelectedItems([]);
      return;
    }
    if (Array.isArray(selection)) {
      if (allowSelectAll && Array.isArray(selection) && selection.includes(selectAllItem)) {
        setSelectedItems(initItems);
        return;
      }
      setSelectedItems(selection);
    } else {
      setSelectedItems([selection]);
    }
  };

  let placeholder = initItems.length === 0 ? 'Loading...' : label;
  if (selectedItems.length > 0) {
    placeholder = '';
  }
  return (
    <Autocomplete
      multiple={multiple}
      disableClearable={!multiple}
      options={items}
      value={multiple ? selectedItems : selectedItems[0]}
      filterSelectedOptions
      onChange={(_, value): void => handleSelection(value)}
      isOptionEqualToValue={(option, value): boolean => option.value === value.value}
      ChipProps={{
        classes: {
          root: classes.chipPaper,
          deleteIcon: classes.chipPaper,
        },
      }}
      classes={{
        noOptions: classes.noOptions,
        clearIndicator: classes.clearIndicator,
        paper: classes.paper,
        popupIndicator: classes.popupIndicator,
      }}
      renderInput={(params): React.ReactNode => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            classes: {
              root: classes.input,
            },
            disableUnderline: true,
          }}
        />
      )}
    />
  );
}
