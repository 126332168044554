import React from 'react';
import { InfoCard } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { get } from 'lodash';
import { useAsync } from 'react-use';
import { CZI_ANNOTATION_STATUS_PAGE_URL } from '../../hasStatusPageAnnotation';

type StatusPageTileProps = {

};

export function StatusPageTile(_props: StatusPageTileProps): JSX.Element {
  const { entity } = useEntity();
  const statusPageUrl = get(entity.metadata.annotations, CZI_ANNOTATION_STATUS_PAGE_URL);

  const { value: statuses } = useAsync(async () => {
    if (!statusPageUrl) {
      return [];
    }

    // TODO: load statuses here
    return [];
  });

  // eslint-disable-next-line no-console
  console.log('...statuses', statuses);

  // TODO:
  // display all endpoints in table
  // display overall status banner
  // add a button to go to status page for historical data

  if (!statusPageUrl) {
    return (
      <InfoCard>
        No status page url annotation found for
        {' '}
        {entity.metadata.name}
        . Please provide a value for the
        {' '}
        {CZI_ANNOTATION_STATUS_PAGE_URL}
        {' '}
        annotation if you would like a status tile here.
      </InfoCard>
    );
  }
  return (
    <InfoCard>
      Here is your status page tile
      {statusPageUrl}
    </InfoCard>
  );
}
