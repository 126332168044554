import React from 'react';
import {
  Box,
} from '@material-ui/core';

export function CardTitle(props: { title: string }): JSX.Element {
  const { title } = props;
  return (
    <Box display="flex" alignItems="center">
      <Box ml={1}>{title}</Box>
    </Box>
  );
}
