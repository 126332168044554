import { ConfigApi, createApiRef } from '@backstage/core-plugin-api';
import { Config } from '@backstage/config';
import { scruffy } from '@chanzuckerberg/scruffy-proto';
import { OktaTokenApi } from 'backstage-plugin-czi-extensions-react';
import { cloneDeep, set } from 'lodash';
import axios, { AxiosRequestConfig } from 'axios';

export const scruffyApiRef = createApiRef<ScruffyAPI>({ id: 'scruffy.api' });

export interface ScruffyAPI {
  listRuns(): Promise<scruffy.Run[]>
  getRun(runId: string): Promise<scruffy.Run>
  listActions(): Promise<scruffy.Action[]>
}

interface ProtoFromJson<T> {
  fromJSON(json: any): T;
}

// Use this to make requests to the Scruffy API
export class ScruffyAPIClient implements ScruffyAPI {
  private readonly config: Config;
  readonly urls: {
    BASE_URL: string
    RUNS_URL: string
    ACTIONS_URL: string
  };

  constructor(private oktaTokenApi: OktaTokenApi, configApi: ConfigApi) {
    this.config = configApi.getConfig('plugins.scruffy');
    const baseUrl = this.config.getString('baseUrl');
    this.urls = {
      BASE_URL: baseUrl,
      RUNS_URL: `${baseUrl}/v1/runs`,
      ACTIONS_URL: `${baseUrl}/v1/actions`,
    };
  }

  async listRuns(): Promise<scruffy.Run[]> {
    const response = await this.fetch(this.urls.RUNS_URL, { method: 'GET' }, scruffy.RunsResponse);
    return response.Runs;
  }

  async getRun(runId: string): Promise<scruffy.Run> {
    const response = await this.fetch(`${this.urls.RUNS_URL}/${runId}`, { method: 'GET' }, scruffy.Run);
    return response;
  }

  async listActions(): Promise<scruffy.Action[]> {
    const response = await this.fetch(this.urls.ACTIONS_URL, { method: 'GET' }, scruffy.ActionsResponse);
    return response.Actions;
  }

  private async fetch<T>(url: string, opts: AxiosRequestConfig, parser: ProtoFromJson<T>): Promise<T> {
    const optsClone = cloneDeep(opts);
    optsClone.url = url;
    set(optsClone, 'headers.Authorization', `Bearer ${await this.getToken()}`);
    const response = await axios.request(optsClone);
    return parser.fromJSON(response.data);
  }

  private async getToken(): Promise<string> {
    const clientId = this.config.getString('oktaClientId');
    return this.oktaTokenApi.getToken(clientId);
  }
}
