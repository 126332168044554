import { RepoUrlPickerUiOptions } from '@backstage/plugin-scaffolder';
import { FieldExtensionComponentProps, useTemplateSecrets } from '@backstage/plugin-scaffolder-react';
import { useApi } from '@backstage/core-plugin-api';
import { scmAuthApiRef } from '@backstage/integration-react';
import React, { useState } from 'react';
import useDebounce from 'react-use/lib/useDebounce';

interface RepoUrlPickerState {
  host?: string;
  owner?: string;
  repoName?: string;
  organization?: string;
  workspace?: string;
  project?: string;
}

function parseRepoPickerUrl(
  url: string | undefined,
): RepoUrlPickerState {
  let host = '';
  let owner = '';
  let repoName = '';
  let organization = '';
  let workspace = '';
  let project = '';

  try {
    if (url) {
      const parsed = new URL(`https://${url}`);
      host = parsed.host;
      owner = parsed.searchParams.get('owner') || '';
      repoName = parsed.searchParams.get('repo') || '';
      organization = parsed.searchParams.get('organization') || '';
      workspace = parsed.searchParams.get('workspace') || '';
      project = parsed.searchParams.get('project') || '';
    }
  } catch {
    /* ok */
  }

  return {
    host, owner, repoName, organization, workspace, project,
  };
}

export function HiddenRepoUrlPicker(props: FieldExtensionComponentProps<string, RepoUrlPickerUiOptions>): JSX.Element {
  const { uiSchema, formData } = props;
  const state = useState<RepoUrlPickerState>(parseRepoPickerUrl(formData))[0];
  const scmAuthApi = useApi(scmAuthApiRef);
  const { setSecrets } = useTemplateSecrets();

  useDebounce(
    async () => {
      const { requestUserCredentials } = uiSchema?.['ui:options'] ?? {};

      if (
        !requestUserCredentials
        || !(state.host && state.owner && state.repoName)
      ) {
        return;
      }

      const [host, owner, repoName] = [
        state.host,
        state.owner,
        state.repoName,
      ].map(encodeURIComponent);

      // user has requested that we use the users credentials
      // so lets grab them using the scmAuthApi and pass through
      // any additional scopes from the ui:options
      const { token } = await scmAuthApi.getCredentials({
        url: `https://${host}/${owner}/${repoName}`,
        additionalScope: {
          repoWrite: true,
          customScopes: requestUserCredentials.additionalScopes,
        },
      });

      // set the secret using the key provided in the the ui:options for use
      // in the templating the manifest with ${{ secrets[secretsKey] }}
      setSecrets({ [requestUserCredentials.secretsKey]: token });
    },
    500,
    [state, uiSchema],
  );

  return (
    <div style={{ display: 'none' }} />
  );
}
