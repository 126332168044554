import React from 'react';
import { HorizontalScrollGrid, InfoCard, Link } from '@backstage/core-components';
import { catalogApiRef, entityRouteRef, useEntity } from '@backstage/plugin-catalog-react';
import { get } from 'lodash';
import { useAsync } from 'react-use';
import { Entity, parseEntityRef, stringifyEntityRef } from '@backstage/catalog-model';
import { Box, Grid, Typography } from '@material-ui/core';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';
import { CZI_ANNOTATION_STATUS_PAGE_URL } from '../../hasStatusPageAnnotation';
import { COLORS } from '../../constants';
import { SuccessIcon } from '../SuccessIcon';
import { FailureIcon } from '../FailureIcon';

export function StatusPagesPanel(): JSX.Element {
  const { entity } = useEntity();
  const entityRoute = useRouteRef(entityRouteRef);
  const catalogclient = useApi(catalogApiRef);

  const statusPages = get(entity.spec, 'statusPages', []) as string[];

  const { value: statusPagesResult } = useAsync(async () => catalogclient.getEntitiesByRefs({
    entityRefs: statusPages,
  }));

  const statusPageUrlPath = `metadata.annotations.${CZI_ANNOTATION_STATUS_PAGE_URL}`;
  const statusPageEntities: Entity[] = (statusPagesResult?.items || []).filter((ent: Entity | undefined): ent is Entity => !!ent);

  const statusPageGridItems = statusPageEntities.map((statusPageEntity: Entity) => {
    const statusPageEntityRef = stringifyEntityRef(statusPageEntity);
    const statusPageComponentUrl = entityRoute(parseEntityRef(statusPageEntityRef));

    const linkToStatusPageComponent = (
      <Link to={statusPageComponentUrl}>
        <Typography><Box display="inline">View Component</Box></Typography>
      </Link>
    );
    const statusPageUrl = get(statusPageEntity, statusPageUrlPath);

    return (
      <Grid item md={5}>
        <InfoCard
          headerStyle={{ backgroundColor: COLORS.success }}
          title={statusPageEntity.metadata.name}
          actions={linkToStatusPageComponent}
        >
          Here is your status page tile
          {statusPageUrl}
        </InfoCard>
      </Grid>
    );
  });

  // TODO: display which status page has issues if something isn't successful
  const overallStatus = statusPageEntities.length > 0 ? (
    <Grid container direction="row">
      <Grid item>
        <SuccessIcon />
      </Grid>
      <Grid item>
        <Typography><Box display="inline">All Systems Operational</Box></Typography>
      </Grid>
    </Grid>
  ) : (
    <FailureIcon />
  );

  return (
    <InfoCard title="Status" subheader={overallStatus}>
      <HorizontalScrollGrid>
        {statusPageGridItems}
      </HorizontalScrollGrid>
    </InfoCard>
  );
}
