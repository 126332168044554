import {
  scaffolderPlugin,
} from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  OktaAppPicker,
  OktaAppPickerSchema,
  validateOktaAppPickerFormDataValidation,
} from './OktaAppPickerExtension';

export const OktaAppPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'OktaAppPicker',
    component: OktaAppPicker,
    schema: OktaAppPickerSchema,
    validation: validateOktaAppPickerFormDataValidation,
  }),
);
