import React, { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

export type ScrollBoxProps = {
  height: number,
  style?: any,
};

ScrollBox.defaultProps = {
  style: {},
};

export function ScrollBox({ children, height, style }: PropsWithChildren<ScrollBoxProps>): JSX.Element {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height={height}
      style={{
        overflow: 'hidden',
        overflowY: 'scroll',
        paddingBottom: '0px',
        marginBottom: '0px',
        ...style,
      }}
    >
      {React.Children.map(children, (child) => (
        <Box mt={1} mb={1}>
          {child}
        </Box>
      ))}
    </Box>
  );
}
