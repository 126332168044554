import { Config } from '@backstage/config';

export function includesAdminGroup(currentUserGroups: string[], { config }: { config: Config }): boolean {
  const adminGroups = config.getOptionalStringArray('permission.adminGroups') || [];
  return isMemberOfGroup(currentUserGroups, adminGroups);
}

export function isMemberOfGroup(currentUserGroups: string[], groupsWithPermission: string[]): boolean {
  return groupsWithPermission.findIndex((group: string) => currentUserGroups.includes(group)) >= 0;
}
