import {
  configApiRef, createApiFactory, createPlugin, createRoutableExtension,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { awsCredentialsApiRef } from 'backstage-plugin-czi-extensions-react';
import { rootRouteRef } from './routes';
import { AWSKeyManagementClient, awsKeyManagementApiRef } from './apis/keyManagementApi';

export const keyManagementPlugin = createPlugin({
  id: 'aws-key-management',
  apis: [
    createApiFactory({
      api: awsKeyManagementApiRef,
      deps: { awsCredentialsApi: awsCredentialsApiRef, identityApi: identityApiRef, configApi: configApiRef },
      factory: ({ awsCredentialsApi, identityApi, configApi }) => new AWSKeyManagementClient(awsCredentialsApi, identityApi, configApi),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const KeyManagementPage = keyManagementPlugin.provide(
  createRoutableExtension({
    name: 'KeyManagementPage',
    component: () => import('./components/KeyManagementRootPage').then((m) => m.KeyManagementRootPage),
    mountPoint: rootRouteRef,
  }),
);
