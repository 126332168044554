import {
  IdentityApi, ConfigApi, createApiRef, createApiFactory, identityApiRef, configApiRef, ApiFactory,
} from '@backstage/core-plugin-api';
import { Credentials, STSClient, AssumeRoleWithWebIdentityCommand } from '@aws-sdk/client-sts';
import { OktaTokenApi, oktaTokenApiRef } from './oktaTokenApi';
import { LocalStorageCache } from '../helpers/localStorageCache';

export const awsCredentialsApiRef = createApiRef<AWSCredentialsAPI>({ id: 'aws.get-credentials' });

export interface AWSCredentialsAPI {
  getProfile(accountName: string, roleNames: string[]): Promise<AWSProfile>
  getProfiles(): Promise<AWSProfile[]>
  getSTSCredential(accountName: string, roleNames: string[]): Promise<Credentials | undefined>
}
interface AWSAccount {
  id: string
  name: string
  alias: string
}
export interface AWSProfile {
  aws_account: AWSAccount
  client_id: string
  issuer_url: URL
  role_arn: string
  role_name: string
}

export interface AWSConfig {
  profiles: AWSProfile[]
}

type ProfileInfoApi = Pick<IdentityApi, 'getProfileInfo' | 'getCredentials'>;

type ApiDependencies = {
  oktaTokenApi: OktaTokenApi
  identityApi: ProfileInfoApi
  configApi: ConfigApi
};

export class AWSCredentials implements AWSCredentialsAPI {
  oktaTokenApi: OktaTokenApi;
  identityAPI: ProfileInfoApi;
  configAPI: ConfigApi;

  static createDefaultApiFactory(): ApiFactory<AWSCredentialsAPI, AWSCredentials, ApiDependencies> {
    return createApiFactory({
      api: awsCredentialsApiRef,
      deps: { oktaTokenApi: oktaTokenApiRef, identityApi: identityApiRef, configApi: configApiRef },
      factory: ({ oktaTokenApi, identityApi, configApi }) => new AWSCredentials(oktaTokenApi, identityApi, configApi),
    });
  }

  constructor(oktaTokenApi: OktaTokenApi, identityApi: ProfileInfoApi, configApi: ConfigApi) {
    this.oktaTokenApi = oktaTokenApi;
    this.identityAPI = identityApi;
    this.configAPI = configApi;
  }

  async getProfiles(opts: { validateFn: (_: any) => boolean } = { validateFn: (_: any) => true }): Promise<AWSProfile[]> {
    const cacheKeyProfileList = 'aws-profile-list-cache';
    const profiles = await LocalStorageCache.get<AWSProfile[]>(cacheKeyProfileList, {
      validateFn: opts.validateFn,
      fetchFn: async () => {
        const awsConfigToken = await this.oktaTokenApi.getToken('aws-config');
        const backendToken = (await this.identityAPI.getCredentials()).token;
        const apiHostUri = this.configAPI.get('backend.baseUrl');
        const profilesResp = await fetch(
          `${apiHostUri}/api/aws/getProfiles?idToken=${awsConfigToken}`,
          {
            headers: {
              Authorization: `Bearer ${backendToken}`,
            },
          },
        );
        const profilesRespJson = await profilesResp.json() as AWSConfig;
        return profilesRespJson.profiles;
      },
      ttlSeconds: 12 * 60 * 60, // 12 hours
    });
    return profiles;
  }

  async getProfile(accountName: string, roleNames: string[]): Promise<AWSProfile> {
    const findProfile = (profileList: AWSProfile[]): AWSProfile | undefined => profileList.find(
      (p) => p.aws_account.name === accountName && roleNames.includes(p.role_name),
    );
    const profiles = await this.getProfiles();
    let foundProfile = findProfile(profiles);
    if (!foundProfile) {
      // force refresh the profiles
      const refreshedProfiles = await this.getProfiles({ validateFn: () => false });
      foundProfile = findProfile(refreshedProfiles);
    }
    if (!foundProfile) {
      throw new Error(`Cannot find AWS profile with account / role, accountName=${accountName}, roleName=oneof[${roleNames.join(',')}]`);
    }
    return foundProfile;
  }

  async getSTSCredential(accountName: string, roleNames: string[]): Promise<Credentials | undefined> {
    const profile = await this.getProfile(accountName, roleNames);
    const profileInfo = await this.identityAPI.getProfileInfo();
    const awsOIDCIDToken = await this.oktaTokenApi.getToken(profile.client_id);
    const client = new STSClient({ region: 'us-west-2' });
    const command = new AssumeRoleWithWebIdentityCommand({
      RoleArn: profile.role_arn,
      RoleSessionName: profileInfo.email,
      WebIdentityToken: awsOIDCIDToken,
      DurationSeconds: 60 * 60,
    });
    try {
      const resp = await client.send(command);
      return resp.Credentials;
    } catch (e) {
      throw new Error(`Cannot assume ${profile.role_name} with this AWS account ${profile.aws_account.name}: ${e}`);
    }
  }
}
