import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, TextField } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
}));

export type TextFieldTagsProps = {
  label: string
  variant?: 'standard' | 'outlined' | 'filled'
  error?: boolean
  helperText?: string
  fullWidth?: boolean
  onItemsChange: React.Dispatch<string[]>
  items: string[]
};

TextFieldTags.defaultProps = {
  variant: 'outlined',
  fullWidth: true,
  error: false,
  helperText: '',
};

export function TextFieldTags(props: TextFieldTagsProps): JSX.Element {
  const classes = useStyles();
  const {
    label, items, onItemsChange, ...other
  } = props;

  const [inputValue, setInputValue] = useState<string>('');

  const addNewItem = (item: string): void => {
    const newItems = [...items];
    const duplicatedValues = newItems.indexOf(item);

    if (duplicatedValues !== -1) {
      setInputValue('');
      return;
    }
    if (!item.replace(/\s/g, '').length) {
      // ignore empty
      return;
    }

    newItems.push(item);
    onItemsChange(newItems);
    setInputValue('');
  };

  const handleKeyDown = (event: any): void => {
    if (event.key === 'Enter') {
      addNewItem(event.target.value.trim());
    }
    if (event.key === 'Backspace' && items.length && !inputValue.length) {
      onItemsChange(items.slice(0, items.length - 1));
    }
  };

  const handleDelete = (item: any): (() => void) => () => {
    const newSelectedItem = [...items];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    onItemsChange(newSelectedItem);
  };

  const handleInputChange = (event: any): void => {
    setInputValue(event.target.value);
  };

  const handleBlur = (): void => {
    addNewItem(inputValue.trim());
  };

  return (
    <TextField
      label={label}
      value={inputValue}
      InputProps={{
        startAdornment: items.length === 0 ? undefined : (
          <div style={{ marginTop: 10, marginRight: 10 }}>
            {items.map((item) => (
              <Chip
                key={item}
                tabIndex={-1}
                label={item}
                className={classes.chip}
                onDelete={handleDelete(item)}
              />
            ))}
          </div>
        ),
        sx: { display: 'flex', flexWrap: 'wrap' },
      }}
      onKeyDown={handleKeyDown}
      onChange={handleInputChange}
      onBlur={handleBlur}
      {...other}
    />
  );
}
