import { Entity } from '@backstage/catalog-model';
import { get } from 'lodash';

export const STATUS_PAGE_SPEC_FIELD = 'statusPages';

export function getStatusPagesForEntity(entity: Entity): string[] {
  return get(entity.spec, STATUS_PAGE_SPEC_FIELD, []) as string[];
}

export function hasStatusPages(entity: Entity): boolean {
  return getStatusPagesForEntity(entity).length > 0;
}
