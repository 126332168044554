import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { HiddenRepoUrlPicker } from './HiddenRepoUrlPicker';

export const HiddenRepoUrlPickerExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'HiddenRepoUrlPicker',
    component: HiddenRepoUrlPicker,
  }),
);
