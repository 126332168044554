import { ScmIntegrationsApi, scmIntegrationsApiRef, ScmAuth } from '@backstage/integration-react';
import {
  AnyApiFactory, configApiRef, createApiFactory, discoveryApiRef, oauthRequestApiRef, oktaAuthApiRef,
} from '@backstage/core-plugin-api';
import { OktaAuth } from '@backstage/core-app-api';
import { AWSCredentials } from 'backstage-plugin-czi-extensions-react';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  AWSCredentials.createDefaultApiFactory(),
  createApiFactory({
    api: oktaAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) => {
      const environment = configApi.getOptionalString('auth.environment');
      return OktaAuth.create({
        discoveryApi,
        oauthRequestApi,
        environment,
        defaultScopes: ['openid', 'email', 'profile', 'offline_access', 'groups'],
      });
    },
  }),
];
