import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { AmazonProfilePickerValidation, AmazonProfilePickerExtension } from './AmazonProfilePicker';

export const AmazonProfilePickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AmazonProfilePicker',
    component: AmazonProfilePickerExtension,
    validation: AmazonProfilePickerValidation,
  }),
);
